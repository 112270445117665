<script lang="ts">
	import linkedin from "@core/assets/icons/linkedin.svg?raw";
	import fastestSnail from "@core/assets/images/fastest-logo-variants/fastest-horizontal-snail-onyx.svg";
	import type { ContactPerson } from "./ContactPerson.js";
	import Icon from "./common/Icon.svelte";
	import Media from "./common/Media.svelte";

	const { contact, isLight, imageSize }: { contact: ContactPerson; isLight?: boolean; imageSize?: number } = $props();
	const [firstname, lastname] = contact.name.split(" ");
</script>

<div
	class="flex lg:items-center flex-row gap-4 md:gap-6 lg:gap-9 max-md:w-full max-md:max-w-96 p-4 rounded-xl bg-transparent"
>
	{#if contact.photo}
		<Media
			width={imageSize ?? 150}
			height={imageSize ?? 150}
			source={{ src: contact.photo, type: "image/webp" }}
			alt={contact.name}
			wrapperClass="shrink-0"
			class="rounded-full aspect-square w-28 md:w-[9.375rem] lg:w-[11.75rem] border-fstonyx border-4 object-cover"
		/>
	{:else}
		<div
			class="aspect-square w-28 md:w-[9.375rem] lg:w-[11.75rem] border-fstonyx border-4 bg-[#D7D7D7] rounded-full flex items-center justify-center shrink-0"
		>
			<Media source={{ src: fastestSnail, type: "image/svg+xml" }} width={64} height={64} alt="No photo" />
		</div>
	{/if}
	<div class="flex flex-col lg:items-start">
		<h3
			class={[
				"my-0 -tracking-[0.02em] text-md md:text-lg leading-8 max-md:leading-normal font-bold font-loos-wide text-left",
				isLight ? "text-fstonyx" : "text-white",
			]}
		>
			<span class="inline-block lg:block">{firstname}</span>
			{lastname}
		</h3>
		<span
			class={[
				"text-left text-xs leading-normal md:text-sm  tracking-[0.015em] mb-2",
				isLight ? "text-fstgreen" : "text-fstonyx",
			]}>{contact.role}</span
		>
		<div class="flex flex-col gap-1.5 sm:gap-0">
			{#if contact.phone}
				<a
					class={[
						"text-left text-xs md:text-base max-md:leading-normal tracking-[0.015em]",
						isLight ? "text-fstgrey-200 text-base" : "text-white font-medium",
					]}
					href="tel:{contact.phone}">{contact.phone}</a
				>
			{/if}
			{#if contact.email}
				<a
					class={[
						"text-left text-xs max-md:leading-normal md:text-base tracking-[0.015em] mb-2",
						isLight ? "text-fstgrey-200 text-base" : "text-white font-medium",
					]}
					href="mailto:{contact.email}">{contact.email}</a
				>
			{/if}
			{#if contact.linkedin}
				<a
					class={[
						"text-left flex hover:underline gap-1 md:gap-2 items-center leading-none text-xs tracking-[0.015em]",
						isLight ? "text-fstgrey-200 !text-base" : "text-white font-medium",
					]}
					target="_blank"
					href="https://www.linkedin.com/in/{contact.linkedin}"
				>
					<Icon icon={linkedin} class="w-3 md:w-4 text-fstonyx" square />
					<span class="mt-0.5">{contact.linkedin}</span>
				</a>
			{/if}
		</div>
	</div>
</div>
