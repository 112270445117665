<script lang="ts">
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import arrowNext from "@core/assets/icons/arrow-next.svg?raw";
	import { getConnectWithFastestPath } from "@core/schema/paths/getConnectWithFastestPath.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import { Background } from "./Background.js";
	import ContactCard from "./ContactCard.svelte";
	import type { ContactPerson } from "./ContactPerson.js";
	import Section from "./Section.svelte";

	const { contacts = [] }: { contacts?: ContactPerson[] } = $props();
	const { tenant } = getGlobalTemplateProps();
</script>

<Section background={Background.Phlox} gridHeadline="container-8 xl:container-6" centered>
	{#snippet headline()}
		<strong class="text-fstonyx"><Formatted text="Zarezervujte si termín" /></strong>
		<Formatted text="bezplatné a nezávazné online konzultace" />
	{/snippet}
	{#snippet desc()}
		<p class="text-md text-center font-medium max-w-2xl xl:max-w-4xl mx-auto mb-1 text-fstonyx">
			<Formatted text="Chcete s námi nezávazně probrat podrobnosti?" />
		</p>
		<p class={["text-fstonyx text-md text-center max-w-3xl mx-auto", contacts.length === 0 && "md:mb-8"]}>
			<Formatted text="Zamluvte si v kalendáři níže čas a termín 30minutové online schůzky zdarma." />
		</p>
		{#if contacts.length > 0}
			<div class="pt-8 pb-8 md:pb-12">
				<div
					class={{
						"grid justify-items-center md:mx-auto  lg:max-w-5xl gap-4 md:gap-12 lg:gap-20": true,
						"grid-cols-1 lg:grid-cols-2": contacts.length > 1,
					}}
				>
					{#each contacts as contact (contact)}
						<ContactCard {contact} />
					{/each}
				</div>
			</div>
		{/if}
		<Link
			variant={ButtonVariant.Green}
			class="mx-auto flex items-center justify-center"
			href={getConnectWithFastestPath(tenant)}
		>
			<span>Domluvit schůzku</span>
			<!-- TODO: Uncomment when reservation form is ready.
			<span class="inline-block md:hidden">Domluvit schůzku</span>
			<span class="hidden md:inline-block">Přejít na rezervační kalendář</span>
			-->
			<Icon icon={arrowNext} class="ml-2 text-fstgrey-900"></Icon>
		</Link>
	{/snippet}
</Section>
